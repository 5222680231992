<!-- 查看勋章 -->
<template>
  <div>
    <van-nav-bar
      title="查看勋章"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="medal" v-if="Detail.is_medal">
      <div class="medalbox">
        <div class="medal-xunzhang">
          <img
            v-if="Detail.task_stage == 1"
            src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
            alt=""
          />
          <img
            v-if="Detail.task_stage == 2"
            src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
            alt=""
          />
          <img
            v-if="Detail.task_stage == 3"
            src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
            alt=""
          />
          <img
            v-if="Detail.task_stage == 4"
            src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
            alt=""
          />
        </div>

        <div class="ctx1">
          恭喜你完成 {{ Detail.task_stage }}阶的练习，做的很棒！
        </div>
        <div class="ctx-title">
          <div class="title-ctx">勋章报告</div>
          <div class="title-fk"></div>
        </div>
        <!-- 第一个选项 -->
        <div class="ctx2">本阶段练习中你可能遇到了哪些阻碍</div>
        <div class="ctx3" v-if="Detail.option1.includes(0)">· 没有时间</div>
        <div class="ctx3" v-if="Detail.option1.includes(1)">·拖延</div>
        <div class="ctx3" v-if="Detail.option1.includes(2)">
          · 比较懒，不想做
        </div>
        <div class="ctx3" v-if="Detail.option1.includes(3)">· 其他</div>

        <!-- 第二个选项 -->
        <div class="ctx2">但是这些方法帮助你克服了它们，坚持完成了练习</div>
        <div class="ctx3" v-if="Detail.option2.includes(0)">
          · 每天固定一个练习时间
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(1)">
          · 完成练习就给自己一个小奖励
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(2)">
          · 找一个“监督”和提醒自己的练习伙伴
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(3)">· 其他</div>
        <!-- 第三个自填 -->
        <div class="ctx2">想要获得更好的练习体验，或许你可以做一点小调整：</div>
        <div class="ctx4">· {{ Detail.comment }}</div>
      </div>
    </div>

    <div class="medal" v-if="Detail.is_medal == '0'">
      <div class="jieduanbaogao">
        <div class="ctx-title">
          <div class="title-ctx">阶段报告</div>
          <div class="title-fk"></div>
        </div>
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/jieduanbaogao.png"
          alt=""
        />
      </div>
      <div class="medalbox">
        <!-- 第一个选项 -->
        <div class="ctx2">本阶段练习中你可能遇到了哪些阻碍</div>
        <div class="ctx3" v-if="Detail.option1.includes(0)">· 没有时间</div>
        <div class="ctx3" v-if="Detail.option1.includes(1)">·拖延</div>
        <div class="ctx3" v-if="Detail.option1.includes(2)">
          · 比较懒，不想做
        </div>
        <div class="ctx3" v-if="Detail.option1.includes(3)">· 其他</div>

        <!-- 第二个选项 -->
        <div class="ctx2">但是这些方法帮助你克服了它们，坚持完成了练习</div>
        <div class="ctx3" v-if="Detail.option2.includes(0)">
          · 每天固定一个练习时间
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(1)">
          · 完成练习就给自己一个小奖励
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(2)">
          · 找一个“监督”和提醒自己的练习伙伴
        </div>
        <div class="ctx3" v-if="Detail.option2.includes(3)">· 其他</div>
        <!-- 第三个自填 -->
        <div class="ctx2">想要获得更好的练习体验，或许你可以做一点小调整：</div>
        <div class="ctx4">· {{ Detail.comment }}</div>

        <div class="ctx5">
          或许你暂时没有完成这个阶段的全部练习，但是在这个过程中收获的体验也很棒！
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMedalDetail, memberLog } from "../../api/login";

export default {
  data() {
    return {
      id: "1",
      Detail: "",
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  mounted() {
    if (this.$route.query.item) {
      this.id = this.$route.query.item.id;
    }
    this.getgetMedalDetail();

    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "查看勋章",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getgetMedalDetail() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: this.id,
      };
      getMedalDetail(data).then((res) => {
        this.Detail = res.data;
      });
    },
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.medal {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f4fafe;
  padding: 20px;
  .medalbox {
    background: #fff;
    padding: 20px;
    box-sizing: border-box;

    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    .medal-xunzhang {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      img {
        width: 150px;
        height: 120px;
      }
    }
    .ctx1 {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #5faefa;
      line-height: 22px;
      text-align: center;
    }
    .ctx-title {
      .title-ctx {
        margin-top: 25px;
        z-index: 999;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        color: #000025;
        line-height: 25px;
      }
      .title-fk {
        width: 35px;
        height: 5px;
        background: #629ee9;
        position: relative;
        bottom: 8px;
        z-index: 998;
        transform: skew(-25deg);
      }
    }
    .ctx2 {
      height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: #000025;
      margin-top: 10px;

      line-height: 18px;
    }
    .ctx3 {
      margin-top: 10px;
      height: 18px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      padding-left: 13px;
    }
    .ctx4 {
      margin-top: 20px;
      height: 18px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      padding-left: 13px;
    }
    .ctx5 {
      font-size: 16px;
      font-weight: 500;
      color: #5faefa;
      line-height: 22px;
    }
  }
  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }
  .jieduanbaogao {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;
    img {
      width: 57px;
      height: 50px;
    }
  }
}
</style>
